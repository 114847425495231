import React, {useEffect, useState} from "react"

import Card from "../components/Card"
import Title from "../components/Title"

import testBtn from "../resource/test-btn.svg"
import mainBtn from "../resource/main-btn.svg"
import textIcon from "../resource/test-icon.svg"

import cloudMain from "../resource/cloud-main.png"
import cloudPd from "../resource/cloud-pd.png"
import cloudDaiLi from "../resource/cloud-daili.png"
import cloudYanShi from "../resource/cloud-yanshi.png"

import mesBj from "../resource/mes-bj.png"
import mesZy from "../resource/mes-zy.png"
import mesYw from "../resource/mes-yw.png"
import mesYf from "../resource/mes-yf.png"

const cloudConfig = [
  {
    src: cloudMain,
    title: "主网-云主站",
    url: "https://admin.ubisor.com",
    testUrl: "http://admin.work.ubisor.com",
  },
  {
    src: cloudPd,
    title: "配网-云主站",
    url: "https://cloud.pd.ubisor.com",
    testUrl: "http://admin.pd.work.ubisor.com",
  },
  {
    src: cloudPd,
    title: "新配网-云主站",
    url: "https://admin.new-pds.ubisor.com",
    testUrl: "http://admin.new-pds.work.ubisor.com",
  },
  {
    src: cloudDaiLi,
    title: "主网-代理商",
    url: "https://fds.ubisor.com",
    testUrl: " http://fds.work.ubisor.com",
  },
  {
    src: cloudYanShi,
    title: "配网-代理商",
    url: "https://fds.pd.ubisor.com",
    testUrl: "http://fds.pd.work.ubisor.com",
  },
]

const mesConfig = [
  {
    src: mesBj,
    title: "生产-MES",
    url: "https://mes.sc.ubisor.com",
    testUrl: "",
  },
  {
    src: mesZy,
    title: "郑研-MES",
    url: "http://mes.zy.ubisor.com/",
    testUrl: "",
  },
  {
    src: mesYw,
    title: "运维-MES",
    url: "https://mes.mirror.ubisor.com",
    testUrl: "",
  },
  {
    src: mesYf,
    title: "研发-MES",
    url: "",
    testUrl: "http://seibertron.work.ubisor.com",
  },
  {
    src: mesYf,
    title: "测试-MES",
    url: "https://mes.cs.ubisor.com",
    testUrl: "",
  },
]
const App = () => {
  const [isMain, setIsMain] = useState(true)

  useEffect(() => {
    document.title = "ubisor业务中台"
  }, [])

  const handleChange = () => {
    setIsMain(!isMain)
  }
  return (
    <div className="main-page">
      <h1>UBISOR业务中台</h1>
      <div className="container">
        <div className="title-container margin-bottom-40">
          <Title>
            <span>优碧云平台</span>
            {!isMain && <img src={textIcon} />}
          </Title>
          <img
            src={isMain ? testBtn : mainBtn}
            onClick={handleChange}
            className="btn"
          />
        </div>
        <div className="cards margin-bottom-50">
          {cloudConfig?.map((cloud, index) => (
            <Card
              src={cloud.src}
              key={index}
              title={cloud.title}
              url={isMain ? cloud.url : cloud.testUrl}
            />
          ))}
        </div>
        <div className="title-container margin-bottom-40">
          <Title>
            <span>MES系统</span>
            {!isMain && <img src={textIcon} className="mes-test-icon" />}
          </Title>
        </div>
        <div className="cards">
          {mesConfig.map((mes, index) => (
            <Card
              key={index}
              src={mes.src}
              title={mes.title}
              url={isMain ? mes.url : mes.testUrl}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default App
